import React, { memo, Fragment, useMemo, useCallback } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import size from 'lodash/size';

import {
  IsFetched,
  ErrorMessage,
  IsPlaceholderData
} from '../../../../../types';
import {
  Checkable,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import {
  OnSelectedMaterialsSidebarCloseAction,
  FetchMaterialsCacheKeys
} from '../../../../materials/materialsTypes';
import {
  SelectedMaterialsListMaterialsSet,
  SelectedMaterialsListMaterialsSetSelectedMaterials,
  SelectedMaterialsListMaterialsSetSelectedMaterial,
  SelectedMaterialsListUpdateMaterialsSetCache
} from './SelectedMaterialsList.types';

import { FetchMaterialsSetsCacheKeys } from '../../../../materialsSets/materialsSetsTypes';

import { SelectedMaterialsListItem } from '../SelectedMaterialsListItem';

import { SelectedMaterialsListItemLightboxBottomButtons } from './components/SelectedMaterialsListItemLightboxBottomButtons';
import { SelectedMaterialsListItemLightboxTitle } from './components/SelectedMaterialsListItemLightboxTitle';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Loading } from '../../../../../helpers/Loading';
import { NoResults } from '../../../../../helpers/NoResults';
import { Checkbox } from '../../../../../helpers/Checkbox';

import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle
} from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

import { materialsKeys } from '../../../../../locales/keys';

interface SelectedMaterialsListProps {
  materialsSet: SelectedMaterialsListMaterialsSet;
  materialsSetFetched: IsFetched;
  materialsSetError: ErrorMessage;
  materialsSetIsPlaceholderData: IsPlaceholderData;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: SelectedMaterialsListUpdateMaterialsSetCache;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  checkable?: Checkable;
  checkedAll?: CheckedAll;
  checkedHash?: CheckedHashItem;
  onCheckAll?: OnCheckAll;
  onSetCheckedIds?: OnSetCheckedIds;
}

function SelectedMaterialsList({
  materialsSet,
  materialsSetFetched,
  materialsSetError,
  materialsSetIsPlaceholderData,
  materialsCacheKeys,
  materialsSetCacheKeys,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache,
  togglePreventModalClose,
  checkable = false,
  checkedAll,
  checkedHash,
  onCheckAll,
  onSetCheckedIds
}: SelectedMaterialsListProps) {
  const selectedMaterials =
    useMemo<SelectedMaterialsListMaterialsSetSelectedMaterials>(
      () => materialsSet?.selectedMaterials || [],
      [materialsSet]
    );

  const selectedMaterialsTotalCount = size(selectedMaterials);

  const selectedMaterialsInLightbox =
    useMemo<SelectedMaterialsListMaterialsSetSelectedMaterials>(() => {
      return filter<SelectedMaterialsListMaterialsSetSelectedMaterial>(
        selectedMaterials,
        (selectedMaterial) =>
          selectedMaterial.material?.image?.file &&
          Files.isImage(selectedMaterial.material.image.file)
      );
    }, [selectedMaterials]);

  const lightboxItems = useMemo(() => {
    const materialImages = selectedMaterialsInLightbox.map((selectedMaterial) =>
      selectedMaterial.material?.image?.file &&
      Files.isImage(selectedMaterial.material.image.file)
        ? selectedMaterial.material.image
        : null
    );

    return compact(materialImages);
  }, [selectedMaterialsInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      const selectedMaterial = selectedMaterialsInLightbox[index];
      if (!selectedMaterial) {
        return [];
      }

      return (
        <SelectedMaterialsListItemLightboxBottomButtons
          selectedMaterial={selectedMaterial}
          materialsSet={materialsSet}
          materialsCacheKeys={materialsCacheKeys}
          materialsSetCacheKeys={materialsSetCacheKeys}
          onSelectedMaterialsSidebarClose={onSelectedMaterialsSidebarClose}
          updateMaterialsSetCache={updateMaterialsSetCache}
        />
      );
    },
    [
      selectedMaterialsInLightbox,
      materialsSet,
      materialsCacheKeys,
      materialsSetCacheKeys,
      onSelectedMaterialsSidebarClose,
      updateMaterialsSetCache
    ]
  );

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(
    ({ index }) => {
      const selectedMaterial = selectedMaterialsInLightbox[index];
      if (!selectedMaterial) {
        return null;
      }

      return (
        <SelectedMaterialsListItemLightboxTitle
          selectedMaterial={selectedMaterial}
        />
      );
    },
    [selectedMaterialsInLightbox]
  );

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={materialsSetError} />
      <Loading loaded={!materialsSetIsPlaceholderData} />
      <LoadingSkeleton
        loaded={materialsSetIsPlaceholderData || materialsSetFetched}
      >
        {selectedMaterialsTotalCount === 0 ? (
          <NoResults addErrorClassName="m-4" />
        ) : (
          <Fragment>
            {checkable && (
              <Checkbox
                dataGa="selected-materials-check-all"
                checked={checkedAll}
                onChange={onCheckAll}
                i18nLabel={materialsKeys.all}
                checkboxWrapperClassName="flex items-center mb-6"
                labelClassName="ml-2 block text-sm text-gray-900 dark:text-gray-100"
              />
            )}

            <ul className="space-y-4">
              {selectedMaterials.map((selectedMaterial) => (
                <SelectedMaterialsListItem
                  key={selectedMaterial.uuid}
                  materialsSet={materialsSet}
                  selectedMaterial={selectedMaterial}
                  materialsSetCacheKeys={materialsSetCacheKeys}
                  onLightboxOpen={handleLightboxOpenOnSlide}
                  onSelectedMaterialsSidebarClose={
                    onSelectedMaterialsSidebarClose
                  }
                  updateMaterialsSetCache={updateMaterialsSetCache}
                  checkable={checkable}
                  checked={checkedHash?.[selectedMaterial.id]}
                  onCheck={onSetCheckedIds}
                />
              ))}
            </ul>
          </Fragment>
        )}
      </LoadingSkeleton>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        renderImageTitle={renderImageTitle}
        renderCustomButtons={renderLightboxButtons}
        withFullScreenButton
      />
    </Fragment>
  );
}

export default memo<SelectedMaterialsListProps>(SelectedMaterialsList);
